<template>
    <div class="falcon-data-table">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 border-bottom border-200">
                <thead class="bg-200 text-900 white-space-nowrap user-select-none">
                    <slot name="header" v-bind="{ sort, sortBy }" />
                </thead>
                <tbody>
                    <template v-for="(item, index) in paginatedRows">
                        <slot name="row" v-bind="{ item, index }" />
                    </template>
                </tbody>
            </table>
        </div>
        <div v-if="pageSize" class="p-3 row align-items-center">
            <div class="col d-flex align-items-center">
                <select class="custom-select custom-select-sm w-auto d-none d-sm-block" v-model="currentPageProxy">
                    <option v-for="pageNum in (pageCount || 1)" :key="pageNum" :value="pageNum">
                        Page {{ pageNum }}
                    </option>
                </select>
                <div class="text-secondary fs--1 ml-sm-2">{{ pageRangeDescription }}</div>
            </div>
            <div class="col-auto d-flex">
                <button type="button"
                        class="btn btn-falcon-default btn-sm"
                        @click="prevPage()"
                        :disabled="currentPage <= 1">
                    <span>Previous</span>
                </button>
                <button type="button"
                        class="btn btn-falcon-default btn-sm px-4 ml-2"
                        @click="nextPage()"
                        :disabled="currentPage >= pageCount">
                    <span>Next</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mixins } from '@medshift/vtable';

    export default {
        mixins: [
            mixins.filtering,
            mixins.sorting,
            mixins.pagination,
        ],
        props: {
            data: { type: Array, required: true },
            // Other props specified in mixins
        },
        computed: {
            pageRangeDescription: vm => {
                const total = vm.filteredRows.length;
                const start = Math.min((vm.currentPage - 1) * vm.pageSize + 1, total);
                const end = Math.min(vm.currentPage * vm.pageSize, total);
                return `${start}\u2013${end} of ${total}`;
            },
            currentPageProxy: {
                // Used for v-model on the page <select>
                get() {
                    return this.currentPage;
                },
                set(value) {
                    this.setPage(value);
                },
            },
        },
    };
</script>
