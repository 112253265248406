<template>
    <span class="badge badge-pill badge-soft-secondary d-block" :class="[badgeColor]">
        {{ status }}<span class="ml-1 fas" :class="[badgeIcon]" data-fa-transform="shrink-2" />
    </span>
</template>

<script>
    export default {
        name: 'BCOrderStatusBadge',
        props: {
            status: {
                type: String,
                required: true,
            },
        },
        computed: {
            badgeColor() {
                switch (this.status) {
                case 'Completed':
                    return 'badge-soft-success';

                case 'Awaiting Shipment':
                case 'Awaiting Pickup':
                case 'Shipped':
                    return 'badge-soft-info';

                case 'Awaiting Fulfillment':
                case 'Manual Verification Required':
                case 'Partially Shipped':
                    return 'badge-soft-warning';

                case 'Disputed':
                case 'Declined':
                case 'Refunded':
                case 'Partially Refunded':
                    return 'badge-soft-danger';

                case 'Cancelled':
                case 'Awaiting Payment':
                case 'Pending':
                default:
                    return 'badge-soft-secondary';
                }
            },
            badgeIcon() {
                switch (this.status) {
                case 'Completed':
                    return 'fa-check';

                case 'Awaiting Fulfillment':
                case 'Awaiting Payment':
                case 'Pending':
                    return 'fa-hourglass-half';

                case 'Awaiting Pickup':
                case 'Awaiting Shipment':
                    return 'fa-dolly';

                case 'Shipped':
                    return 'fa-truck';

                case 'Partially Shipped':
                    return 'fa-redo';

                case 'Declined':
                case 'Disputed':
                case 'Manual Verification Required':
                    return 'fa-exclamation-circle';

                case 'Cancelled':
                case 'Partially Refunded':
                case 'Refunded':
                    return 'fa-ban';

                default:
                    return 'fa-question-circle';
                }
            },
        },
    };
</script>
