<template>
    <div class="d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center flex-column">
            <i class="fas fa-exclamation-triangle" style="font-size: 2rem;" />
            <span class="mt-3">Oops! Something went wrong.</span>
            <button class="btn btn-sm btn-falcon-default mt-3" @click="refreshPage()">Click to refresh</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoadingError',
        methods: {
            refreshPage() {
                window.location.reload(true);
            },
        },
    };
</script>
