<template>
    <div class="card mb-3">
        <div class="card-header">
            <div class="row align-items-center justify-content-between">
                <div class="col-4 col-sm-auto">
                    <h5 class="fs-0 mb-0 text-nowrap">Orders</h5>
                </div>
                <div class="col-6 col-sm-5 col-xxl-4">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="Search..."
                           aria-label="Search"
                           v-model="search">
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <LoadingSpinner v-if="loading" style="height: 300px;" />
            <LoadingError v-else-if="loadingError" style="height: 300px;" />
            <VTable v-else-if="orders.length"
                    :data="orders"
                    :filter="search"
                    :filter-fields="['bigcommerce_order_id', 'created_formatted', 'placed_by.full_name', 'status_name']"
                    :initial-sort="{ field: 'bigcommerce_order_id', order: false }"
                    :page-size="15">
                <template #header="{ sort, sortBy }">
                    <tr>
                        <VTableHeader field="bigcommerce_order_id" v-bind="{ sort, sortBy }">
                            ID
                        </VTableHeader>
                        <VTableHeader field="created" v-bind="{ sort, sortBy }">
                            Date
                        </VTableHeader>
                        <VTableHeader field="placed_by.full_name" v-bind="{ sort, sortBy }">
                            Placed By
                        </VTableHeader>
                        <VTableHeader field="status_name" v-bind="{ sort, sortBy }" class="text-right pr-3">
                            Status
                        </VTableHeader>
                    </tr>
                </template>
                <template #row="{ item: order }">
                    <tr :key="order.id">
                        <th class="py-2 align-middle white-space-nowrap">
                            <router-link :to="{ name: 'order-details', params: { id: order.id }}">
                                <strong>#{{ order.bigcommerce_order_id }}</strong>
                            </router-link>
                        </th>
                        <td class="py-2 align-middle">{{ order.created_formatted }}</td>
                        <td class="py-2 align-middle">{{ order.placed_by.full_name }}</td>
                        <td class="py-2 align-middle text-center fs-0 white-space-nowrap pr-3">
                            <BCOrderStatusBadge :status="order.status_name" />
                        </td>
                    </tr>
                </template>
            </VTable>
            <div v-else class="d-flex align-items-center justify-content-center flex-column p-3" style="height: 300px;">
                <i class="fas fa-store-slash" style="font-size: 2rem;" />
                <span class="mt-3">No orders available yet.</span>
                <small class="mt-2 w-50 text-center">
                    Once your store begins to receive orders, they will begin to appear in this section
                </small>
            </div>
        </div>
    </div>
</template>

<script>
    import BCOrderStatusBadge from '../components/BCOrderStatusBadge.vue';
    import { formatISODate } from '../filters';
    import { getOrders } from '../services/OrderService';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';
    import VTable from '../components/VTable.vue';
    import VTableHeader from '../components/VTableHeader.vue';

    export default {
        name: 'Orders',
        components: {
            BCOrderStatusBadge,
            VTable,
            VTableHeader,
            LoadingSpinner,
            LoadingError,
        },
        data() {
            return {
                loading: true,
                loadingError: false,
                orders: [],
                search: '',
            };
        },
        mounted() {
            this.getOrders();
        },
        methods: {
            async getOrders() {
                this.loading = true;
                try {
                    const response = await getOrders();
                    // Add formatted fields
                    response.data.forEach(order => {
                        order.placed_by.full_name = `${order.placed_by.first_name} ${order.placed_by.last_name}`;
                        order.created_formatted = formatISODate(order.created, 'MMM d, YYY');
                    });
                    this.orders = response.data;
                    this.loadingError = false;
                } catch (error) {
                    this.loadingError = true;
                    this.$alerts.danger('An error occurred',
                                        'This page failed to load. Please contact support if this problem persists.');
                }
                this.loading = false;
            },
        },
    };
</script>
